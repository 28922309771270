body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.upload-file-css .ant-upload-select {
    width: 100%;
}
/* 
#audio_controls::-webkit-media-controls-timeline {
    display: none;
} */

#audio_controls::-webkit-media-controls-current-time-display,
#audio_controls::-webkit-media-controls-time-remaining-display {
    display: none;
}
a{text-decoration: none !important;}
