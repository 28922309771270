.adminPanel * {
  box-sizing: border-box;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
.ant-layout-sider-children{
  heigth: 100%;
  overflow: auto;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout-header {
  border-radius: 15px;
  margin: 5px;
  position: sticky;
  top: 5px;
  right: 10px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.616) !important;
  backdrop-filter: blur(3px);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.adminPanel {
  width: 100%;
  height: 100vh;
  padding: 0px 5px;
  overflow: hidden;
}
.adminPanel .ant-layout {
  height: 100%;
}
.adminPanel .ant-layout .ant-layout {
  height: 100vh;
  position: relative;
  overflow: auto !important;
}
.adminPanel .ant-layout-sider {
  background-color: transparent !important;
  max-width: 220px !important;
  padding: 5px !important;
}
.ant-menu {
  background-color: white;
  padding-bottom: 20px;
}
.adminPanel .logo {
  width: 100%;
  font-weight: bold;
  font-size: 25px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background-color: white;
  display: flex;
  align-items: center;
  height: 64px;
  border-radius: 15px;
  margin-bottom: 10px;
  justify-content: center;
}
.ant-menu {
  border-radius: 15px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.btnMenu {
  width: calc(100% - 8px);
  height: 40px;
  padding: 0px 15px;
  cursor: pointer;
  z-index: 2132;
  margin: 4px 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btnMenu:hover {
  background-color: #e6f4ff;
}
.ant-layout-sider-collapsed .btnMenu{justify-content: center; padding: 0px;}
.ant-layout-content {
  margin: 0px !important;
  background-color: transparent !important;
  padding: 0px !important;
}

.adminBox {
  margin: 5px !important;
  border-radius: 15px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  width: calc(100% - 10px);
  min-height: 200px;
  padding: 20px;
}

.admin_btns {
  position: fixed;
  top: 0px;
  height: 64px;
  z-index: 3;
  display: flex;
  align-items: center;
}
.admin_btns p {
  margin-left: 20px;
  margin-bottom: 0px;
}
.admin_btns button {
  margin-bottom: 0px !important;
}
p {
  font-size: 14px !important;
  font-weight: 400 !important;
}
strong {
  font-weight: 700 !important;
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3434;
  background-color: rgba(3, 3, 58, 0.712);
  backdrop-filter: blur(5px);
  color: white;
  font-weight: bold;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button:disabled {
  background-color: rgb(165, 165, 165) !important;
}
.ant-menu svg {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.ant-menu-inline-collapsed svg {
  margin-right: 20px;
}

.ql-container{height: 400px !important;}
.basic_description{height: 400px !important;}